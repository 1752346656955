<template>
    <div class="mailTable">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询<em style="opacity: 0">查询</em></span>
                    <el-input
                        size="small"
                        style="width: 230px"
                        placeholder="公司名称、联系人、联系方式"
                        v-model="fieldVla"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        style="background: #2370eb"
                        icon="el-icon-search"
                    ></el-button>
                    <!-- <span v-if="btnP.depSearch">部门</span>
                    <el-select
                        v-model="dempVal"
                        size="small"
                        v-if="btnP.depSearch"
                        style="width: 170px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId()"
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.searchUser">成员</span>
                    <el-select
                        v-if="btnP.searchUser"
                        v-model="sourceAdminIdVal"
                        placeholder="请选择"
                        size="small"
                        style="width: 180px"
                        clearable
                        @change="getTableData"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                    <Department
                        ref="department"
                        :departmentWidth="170"
                        :adminWidth="180"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.searchUser"
                        @searchData="searchData"
                    ></Department>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 12px;
                            background: #2370eb;
                            border-color: #2370eb;
                        "
                        size="small"
                        @click="getTableData"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0 16px 0 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>

            <div class="search-two" v-show="fold">
                <p>
                    <span>选择标签</span>
                    <el-button
                        @click="onChoiceLabel"
                        type="primary"
                        size="small"
                        style="
                            border-color: #e5e5e5;
                            background: none;
                            color: #333333;
                        "
                        >请选择
                        <i
                            class="el-icon-arrow-right"
                            style="color: #2162cb"
                        ></i>
                    </el-button>
                </p>
                <div class="labelList" v-show="!dialogAddlabel">
                    <p
                        v-show="!drawerDetail && !drawerAdd"
                        :class="item.category == 0 ? 'blue' : 'yellow'"
                        v-for="item in $choiceLabel.state.labelList"
                    >
                        {{ item.tagName }}
                        <span class="cancel" @click="onDeleteLabel(item)">
                            <span class="el-icon-close"></span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>联系人列表</p>
            <span>
                <button v-if="btnP.addMail" @click="addMail">
                    <i class="el-icon-plus"></i> 新增联系人
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
                width="160"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="姓名" show-overflow-tooltip width="55">
                <template slot-scope="scope">
                    <el-button
                        @click="onDetails(scope.row)"
                        type="text"
                        style="color: #2370eb"
                    >
                        {{ scope.row.contactName }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column prop="contactPhone" label="联系方式" width="105">
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        v-if="
                            scope.row.bookWayDOList &&
                            scope.row.bookWayDOList.length > 0
                        "
                        effect="dark"
                        placement="top"
                    >
                        <div slot="content">
                            <p
                                v-for="item in scope.row.bookWayDOList"
                                :key="item.id"
                            >
                                <span>{{
                                    $tableDataHandle.contactWay(item)
                                }}</span>
                                {{ item.contactContent }}
                            </p>
                        </div>
                        <p>
                            {{ scope.row.bookWayDOList[0].contactContent }}
                        </p>
                    </el-tooltip>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="companyName"
                label="公司名称"
                show-overflow-tooltip
                width="239"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="departmentName"
                label="部门"
                show-overflow-tooltip
                v-if="btnP.depSearch"
                width="80"
            >
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column v-if="btnP.depSearch" min-width="20">
            </el-table-column>
            <el-table-column
                width="55"
                prop="adminName"
                label="成员"
                show-overflow-tooltip
                v-if="btnP.searchUser"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20" v-if="btnP.searchUser">
            </el-table-column>
            <el-table-column label="标签" width="150">
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        v-if="scope.row.tagList && scope.row.tagList.length > 0"
                    >
                        <div slot="content">
                            <span v-if="scope.row.tagList.length > 0">
                                <span v-for="item in scope.row.tagList"
                                    >{{ item.tagName }}&nbsp;&nbsp;</span
                                >
                            </span>
                        </div>
                        <span v-if="scope.row.tagList.length > 0">
                            <span
                                class="Times"
                                v-for="item in scope.row.tagList"
                                >{{ item.tagName }}</span
                            >
                        </span>
                        <span v-else>- -</span>
                    </el-tooltip>
                    <!-- <span v-if="scope.row.tagList.length > 0">
                        <span class="Times" v-for="item in scope.row.tagList">{{
                            item.tagName
                        }}</span>
                    </span> -->
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="remark"
                label="备注"
                width="173"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.remark || '- -' }}
                    </div>
                </template>
            </el-table-column> -->

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleAdd"
            size="480px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">新增联系人</div>
            </template>
            <AddComm @close="handleAdd" ref="addComm" />
        </el-drawer>
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleDetail"
            size="480px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">联系人详情</div>
            </template>
            <DetailsMail @close="handleDetail" ref="details" />
        </el-drawer>
        <!-- 标签搜索 -->
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                :isGet="isGet"
                @addLabel="addLabel"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>
<script>
import { addressList } from '@/api/user/mail.js';
import { roleList } from '@/api/user/clue';
import AddComm from './addMail.vue';
import DetailsMail from './detailsMail.vue';
import ChoiceLabel from './choiceLabel.vue';
import AddLable from '../../components/choice-label/addLabel.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../components/Department';
export default {
    components: {
        AddComm,
        DetailsMail,
        ChoiceLabel,
        AddLable,
        Department,
    },
    data() {
        return {
            isGet: false,
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            fold: true,
            field: '',
            fieldVla: '',
            dateChoice: '',
            searchDate: [],
            drawerAdd: false,
            direction: 'rtl',
            drawerDetail: false,
            isPage: false,
            dialogChoice: false,
            dialogAddlabel: false,
            sourceAdminIdVal: '',
            userName: [],
            btnP: {},
            dempData: [],
            pageData: {},
            dempVal: '',
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            if (
                JSON.parse(sessionStorage.getItem('pageData'))
                    .sourceAdminIdVal &&
                JSON.parse(sessionStorage.getItem('pageData')).dempVal
            ) {
                this.$refs.department.getUserList(
                    JSON.parse(sessionStorage.getItem('pageData')).dempVal
                );
            }
            this.$refs.department.$data.adminId = this.sourceAdminIdVal =
                JSON.parse(sessionStorage.getItem('pageData')).sourceAdminIdVal;
            this.$refs.department.$data.departmentId = this.dempVal =
                JSON.parse(sessionStorage.getItem('pageData')).dempVal;
            this.fieldVla = JSON.parse(
                sessionStorage.getItem('pageData')
            ).fieldVla;

            if (JSON.parse(sessionStorage.getItem('pageData')).tagList) {
                this.$choiceLabel.commit(
                    'getLabelList',
                    JSON.stringify(
                        JSON.parse(sessionStorage.getItem('pageData')).tagList
                    )
                );
            }
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);

            if (btn.depSearch) {
                this.getDempData();
            }
            if (btn.searchUser && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.dempVal = data.departmentId;
            this.sourceAdminIdVal = data.adminId;
            this.getTableData();
        },
        getTableData() {
            let data = {
                param: {
                    category: 1,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            // if (this.field != '' && this.fieldVla == '') {
            //     return this.$message.error('请输入查询字段内容');
            // } else if (this.field == '' && this.fieldVla != '') {
            //     return this.$message.error('请选择查询字段');
            // } else if (this.field == 1) {
            //     data.param.companyName = this.fieldVla;
            // } else if (this.field == 2) {
            //     data.param.contactPhone = this.fieldVla;
            // } else if (this.field == 3) {
            //     data.param.contactName = this.fieldVla;
            // }
            if (this.fieldVla) {
                data.param.name = this.fieldVla;
            }
            data.param.category = 2;
            let labelArr = this.$choiceLabel.state.labelList;
            if (labelArr && labelArr.length > 0) {
                data.param.tagList = labelArr;
            }
            if (this.btnP.searchUser && !this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else if (!this.btnP.searchUser && !this.btnP.depSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.sourceAdminIdVal != '') {
                data.param.adminId = this.sourceAdminIdVal;
            }
            if (this.dempVal != '') {
                data.param.departmentId = this.dempVal;
            }
            this.pageData = {
                sourceAdminIdVal: this.sourceAdminIdVal,
                dempVal: this.dempVal,
                fieldVla: this.fieldVla,
                pageNum: this.currentPage,
                pageSize: this.pagesize,
                tagList: labelArr,
            };
            addressList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },
        // 单选时间
        clickitem(e) {
            e === this.dateChoice
                ? (this.dateChoice = '')
                : (this.dateChoice = e);
            this.getTableData();
        },
        // 时间输入框变化
        expireTime() {
            if (this.searchDate && this.searchDate.length > 0) {
                this.dateChoice = '';
            }
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 新增
        addMail() {
            this.drawerAdd = true;
            // setTimeout(() => {
            //     this.$refs.addComm.getRelation();
            // }, 50);
        },
        handleAdd() {
            this.$choiceLabel.commit('emptyData');
            this.drawerAdd = false;
            this.getTableData();
        },
        // 详情
        onDetails(row) {
            // if (this.btnP.mailUpdata) {
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getIsDetails', true);
            this.$choiceLabel.commit('getType', '3');
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$router.push({
                path: '/contractdetails',
                query: {
                    id: row.id,
                },
            });
            // }
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        handleDetail() {
            this.$formDetails.commit('emptyData');
            this.$choiceLabel.commit('emptyData');
            this.drawerDetail = false;
            this.getTableData();
        },

        // 标签查询
        onChoiceLabel() {
            this.$choiceLabel.commit('emptyData');
            this.dialogChoice = true;
            this.$choiceLabel.commit('getType', '3');
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
            }, 200);
        },
        handleClose() {
            this.dialogChoice = false;
            this.getTableData();
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            this.getTableData();
        },
        // 重置
        searchEmpty() {
            this.field = '';
            this.fieldVla = '';
            this.dateChoice = '';
            this.searchDate = [];
            this.$choiceLabel.commit('emptyData');
            this.getTableData();
        },
    },
    destroyed() {
        this.$choiceLabel.commit('emptyData');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.mailTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .search-two {
            padding-bottom: 10px;
            display: flex;
            align-items: flex-start;
            align-items: center;
            .labelList {
                display: inline-block;
                width: 80%;
                .cancel {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    color: #fff;
                    border-top: 10px solid rgba(255, 255, 255, 0);
                    border-right: 10px solid rgba(255, 255, 255, 0);
                    border-bottom: 10px solid rgba(255, 255, 255, 0);
                    border-left: 10px solid yellow;
                    -webkit-transform: rotate(45deg); /* for Chrome || Safari */
                    -moz-transform: rotate(45deg); /* for Firefox */
                    -ms-transform: rotate(45deg); /* for IE */
                    -o-transform: rotate(45deg);
                    right: -26px;
                    bottom: -10px;
                    .el-icon-close {
                        color: #fff;
                        position: absolute;
                        font-size: 8px;
                        left: -28px;
                        top: -6px;
                        -webkit-transform: rotate(
                            45deg
                        ); /* for Chrome || Safari */
                        -moz-transform: rotate(45deg); /* for Firefox */
                        -ms-transform: rotate(45deg); /* for IE */
                        -o-transform: rotate(45deg);
                    }
                }
                p {
                    position: relative;
                    display: inline-block;
                    width: 80px;
                    line-height: 30px;
                    border-radius: 2px;
                    border: 1px solid #2370eb;
                    margin-left: 16px;
                    text-align: center;
                    font-size: 14px;
                }
                .blue {
                    color: #2370eb;
                    border-color: #2370eb;
                    .cancel {
                        border-left: 10px solid #2370eb;
                    }
                }
                .yellow {
                    color: #fa8c15;
                    border-color: #fa8c15;
                    .cancel {
                        border-left: 10px solid #fa8c15;
                    }
                }
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
        button {
            font-size: 12px;
            width: 88px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            color: #333333;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }

    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        height: 24px;
        background: #fff2ed;
        border-radius: 2px;
        border: 1px solid #ff7a44;
        display: inline-block;
        padding: 2px 5px;
        margin-right: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #ff7a45;
    }
}
</style>
<style></style>
